import styled from '@emotion/styled';
import Link from 'next/link';
import { useCallback } from 'react';

import { breakPoints } from 'constant/breakPoints';

const SpecializedItem = ({
  icon,
  title,
  description,
  link = '',
  keyItem,
  keyActive,
  setIdxActive,
}: {
  icon: string;
  title: string;
  description: string;
  link: string;
  keyItem: number;
  keyActive: number;
  setIdxActive: (key: number) => void;
}): JSX.Element => {
  const onClick = useCallback(() => {
    if (keyActive === keyItem) setIdxActive(-1);
    else setIdxActive(keyItem);
  }, [keyActive, keyItem, setIdxActive]);

  return (
    <Wrap
      className={`${keyActive === keyItem ? 'collapse-in' : ''} ${
        keyItem === 3 ? 'specialized-more-width' : ''
      }`}
    >
      <Left onClick={onClick}>
        <ImageWrap>
          <img src={icon} alt="Spirit labs" />
        </ImageWrap>
        <p dangerouslySetInnerHTML={{ __html: title }} />
        <CollapseIcon />
      </Left>
      <Right>
        <p>{description}</p>
        <Link href={link} prefetch={false}>
          <a aria-label={description} className="font-medium button-link">
            Read more {'>'}
          </a>
        </Link>
      </Right>
    </Wrap>
  );
};

export default SpecializedItem;

const CollapseIcon = styled.div`
  position: relative;
  display: none;
  flex: 0 0 24px;
  height: 24px;
  margin-right: 0;
  margin-left: auto;
  margin-top: 15px;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.2s ease;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 100%;
    background-color: #fff;
    transition: all 0.5s ease;
  }
`;

const Left = styled.div`
  padding: 45px 31px;
  width: 256px;
  flex: 0 0 256px;

  p {
    font-weight: 500;
    width: max-content;
    font-size: 24px;
  }

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    display: flex;
    width: 100%;
    padding: 0;

    p {
      font-size: 18px;
      margin-left: 25px;
      align-self: flex-start;
    }
  }
`;

const ImageWrap = styled.div`
  margin-bottom: 24px;

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    flex: 0 0 48px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const Right = styled.div`
  padding: 32px 10px 32px 0;
  width: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: width 0.4s ease;

  * {
    display: none;
    opacity: 0;
    width: max-content;
  }

  p {
    width: 321px;
    white-space: pre-wrap;
    line-height: 22.4px;
  }

  a {
    padding: 13px 33px;
  }

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    padding: 0;

    p {
      margin-bottom: 32px;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  position: relative;
  height: 262px;
  min-width: 321px;
  width: min-content;
  padding: 0 16px;

  div {
    z-index: 1;
  }

  &:before {
    z-index: 0;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 321px;
    height: 100%;
    opacity: 0;
    transition: all 0.4s ease;
    background: linear-gradient(
      302.05deg,
      #a336d9 16.75%,
      rgba(163, 54, 217, 0.6) 73.45%
    );
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 16px;
    background-color: #ca8be9;
    height: 3px;
    width: calc(100% - 32px);
  }

  @keyframes showText {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    display: block;
    height: auto;
    width: 100%;
    padding: 24px 16px;
    padding-top: 30px;

    ${CollapseIcon} {
      display: block;
    }

    &:before {
      width: 100%;
      z-index: -1;
      height: 0;
    }

    &.collapse-in {
      ${Right} {
        * {
          display: initial;
          animation: showText 0.1s ease 0.2s forwards;
        }
      }

      &:before {
        opacity: 1;
        width: 100%;
        height: 100%;
      }

      ${CollapseIcon} {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }

  @media screen and (min-width: ${breakPoints.tabletM + 1}px) {
    &:hover {
      ${Left} {
        border-color: transparent;
      }

      ${Right} {
        width: 359px;

        * {
          display: initial;
          animation: showText 0.1s ease 0.2s forwards;
        }
      }

      &:before {
        opacity: 1;
        width: calc(317px + 321px);
      }

      &:after {
        opacity: 0;
      }
    }

    &.specialized-more-width:hover {
      ${Right} {
        width: 450px;

        p {
          width: 450px;
        }
      }

      &:before {
        width: calc(317px + 450px);
      }
    }
  }
`;
