import styled from '@emotion/styled';
import SpecializedItem from './Item';
import Slider from 'react-slick';
import { useCallback, useMemo, useRef, useState } from 'react';
import { breakPoints } from 'constant/breakPoints';
import { SERVICES } from 'constant/appConstant';
import { RectangleBlur } from 'styles/styled';

const Specialized = (): JSX.Element => {
  const refSlider = useRef<Slider>(null);
  const [idxActive, setIdxActive] = useState(-1);

  const onNext = useCallback(() => {
    refSlider.current?.slickNext();
  }, []);

  const onPrev = useCallback(() => {
    refSlider.current?.slickPrev();
  }, []);

  const settings = useMemo(
    () => ({
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
      swipeToSlide: true,
      speed: 1000,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      rows: 1,
      nextArrow: (
        <ButtonControl onClick={onPrev}>
          <img src="/images/icon/arrow_right.svg" alt="Spirit labs" />
        </ButtonControl>
      ),
      prevArrow: (
        <ButtonControl onClick={onNext}>
          <img src="/images/icon/arrow_left.svg" alt="Spirit labs" />
        </ButtonControl>
      ),
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 926,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            autoplay: false,
            rows: SERVICES.length,
          },
        },
      ],
    }),
    [onNext, onPrev],
  );

  return (
    <Wrap>
      <RectangleBlur
        width="398px"
        maxWidth="397.76px"
        rotate={45.07}
        right="-250px"
        top="50px"
      />
      <Title className="text-center title-section">SERVICES WE OFFER</Title>
      <Slider {...settings} ref={refSlider}>
        {SERVICES.map((item, idx) => (
          <SpecializedItem
            {...item}
            key={idx}
            keyItem={idx}
            keyActive={idxActive}
            setIdxActive={setIdxActive}
          />
        ))}
      </Slider>
    </Wrap>
  );
};

export default Specialized;

const Wrap = styled.div`
  padding-top: 15.2%;
  padding-bottom: 50px;
  position: relative;

  .slick-slider {
    padding-left: 145px;

    .slick-slide {
      margin-right: 20px;
      padding-left: 20px;
    }

    .slick-arrow::before {
      display: none;
    }
  }

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    .slick-slider {
      padding: 0;
      margin-top: 20px;

      .slick-slide {
        margin-right: 0;
        padding-left: 0;
      }
    }
  }
`;

const Title = styled.div`
  position: relative;
  width: fit-content;

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`;

const ButtonControl = styled.button`
  background-color: transparent;
  border: none;
  margin-left: 44px;
  padding: 10px;
  opacity: 0.5;
  position: absolute;
  top: calc(100% + 96px);
  z-index: 100;
  left: initial;

  &.slick-arrow {
    height: 32px;
    width: max-content;
    box-sizing: content-box;

    img {
      height: 32px;
      width: auto;
    }
  }

  &.slick-next {
    right: calc(10.62%);
  }

  &.slick-prev {
    right: calc(10.62% + 64px);
  }

  &.slick-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed;
  }

  &:hover {
    opacity: 1;
  }
`;
